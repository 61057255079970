export const examinationsGroupArray = [
  {
    id: 1,
    name: "Mintavétel",
    description: "",
    // SEO and Routing Configuration
    urlSlug: "mintavetel",
    categoryType: "mintavétel",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/mintavetel",
    metaDescription:
      "Professzionális vérvétel, mintavétel és laborvizsgálatok a Bálint Analitika Laboratóriumában. Pontos diagnosztika, megbízható eredmények.",
    metaKeywords: "mintavétel, laborvizsgálat, vérvétel, Bálint Analitika",

    // Schema.org structured data for rich snippets
    schemaData: {
      "@context": "https://schema.org",
      "@type": "MedicalTest",
      name: "Mintavétel szolgáltatások",
      description:
        "Professzionális vérvétel, mintavétel és laborvizsgálatok a Bálint Analitika Laboratóriumában.",
      availableService: {
        "@type": "MedicalProcedure",
        name: "Vérvétel és mintagyűjtés",
        procedureType: "https://schema.org/BloodTest",
      },
    },

    // Visual Assets
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/vervetel_category_card_398x200.png",
    picAttr: "Image by Freepik",
    heroImage:
      "/assets/medical/examinations/categories/vervetel/vervetel_banner_nagy.png",
    heroImageSmall:
      "/assets/medical/examinations/categories/vervetel/vervetel_banner_kicsi.png",

    // Component reference (optional - for special cases)
    component: "ExamCategoryMintavetel",

    typeOfUser: "civil",
  },
  {
    id: 2,
    name: "Otthoni mintavétel",
    description: "",
    // SEO and Routing Configuration
    urlSlug: "otthoni-mintavetel",
    categoryType: "otthoni_mintavétel",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/otthoni-mintavetel",
    metaDescription:
      "Kényelmes otthoni mintavétel szolgáltatás Budapesten és környékén. Professzionális vérvétel az otthonában a Bálint Analitika szakértőitől.",
    metaKeywords:
      "otthoni mintavétel, otthoni vérvétel, házhoz jövő vérvétel, Bálint Analitika",

    // Schema.org structured data for rich snippets
    schemaData: {
      "@context": "https://schema.org",
      "@type": "MedicalTest",
      name: "Otthoni mintavétel",
      description:
        "Kényelmes otthoni vérvétel és mintavétel szolgáltatás a Bálint Analitika szakértőitől.",
      availableService: {
        "@type": "MedicalProcedure",
        name: "Otthoni vérvétel",
        procedureType: "https://schema.org/BloodTest",
      },
    },

    // Visual Assets
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/otthoni_vervetel_category_card_398x200.png",
    picAttr: "Image by Freepik",
    heroImage:
      "/assets/medical/examinations/categories/otthoni_vervetel/otthoni_vervetel_banner_nagy.png",
    heroImageSmall:
      "/assets/medical/examinations/categories/otthoni_vervetel/otthoni_vervetel_banner_kicsi.png",

    typeOfUser: "civil",
  },
  {
    id: 3,
    name: "Hematológia",
    description: "",
    // SEO and Routing Configuration
    urlSlug: "hematologia",
    categoryType: "hematológia",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/hematologia",
    metaDescription:
      "Átfogó hematológiai vizsgálatok a Bálint Analitika Laboratóriumában. Vérképvizsgálatok, vérsejtszámlálás, anémia vizsgálatok.",
    metaKeywords:
      "hematológia, vérkép, vérsejtszámlálás, vérszegénység vizsgálat",

    // Visual Assets
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/hematologia_category_card_398x200.png",
    picAttr: "Image by Freepik",
    heroImage:
      "/assets/medical/examinations/categories/hematologia/hematologia_banner_nagy.png",
    heroImageSmall:
      "/assets/medical/examinations/categories/hematologia/hematologia_banner_kicsi.png",

    typeOfUser: "civil",
  },
  {
    id: 4,
    name: "Vércsoport",
    description: "",
    // SEO and Routing Configuration
    urlSlug: "vercsoport",
    categoryType: "vércsoport",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/vercsoport",
    metaDescription:
      "Vércsoport meghatározási vizsgálatok a Bálint Analitika Laboratóriumában. Pontos ABO és Rh faktor meghatározás, gyors eredmények.",
    metaKeywords:
      "vércsoport vizsgálat, ABO meghatározás, Rh faktor, vércsoporttípus",

    // Schema.org structured data for rich snippets
    schemaData: {
      "@context": "https://schema.org",
      "@type": "MedicalTest",
      name: "Vércsoport meghatározás",
      description:
        "Vércsoport meghatározási vizsgálatok a Bálint Analitika Laboratóriumában.",
      availableService: {
        "@type": "MedicalProcedure",
        name: "Vércsoport meghatározás",
        procedureType: "https://schema.org/BloodTest",
      },
    },

    // Visual Assets
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/vercsoport_category_card_398x200.png",
    picAttr: "Image by Freepik",
    heroImage:
      "/assets/medical/examinations/categories/vercsoport/vercsoport_banner_nagy.png",
    heroImageSmall:
      "/assets/medical/examinations/categories/vercsoport/vercsoport_banner_kicsi.png",

    typeOfUser: "civil",
  },
  {
    id: 5,
    name: "Vizelet",
    description: "",
    // SEO and Routing Configuration
    urlSlug: "vizelet",
    categoryType: "vizelet",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/vizelet",
    metaDescription:
      "Átfogó vizeletvizsgálatok a Bálint Analitika Laboratóriumában. Rutin vizeletvizsgálat, üledékvizsgálat, mikroalbuminuria és egyéb tesztek.",
    metaKeywords:
      "vizeletvizsgálat, vizelet analízis, vizelet teszt, vizelet laborvizsgálat",

    // Schema.org structured data for rich snippets
    schemaData: {
      "@context": "https://schema.org",
      "@type": "MedicalTest",
      name: "Vizeletvizsgálatok",
      description:
        "Átfogó vizeletvizsgálatok a Bálint Analitika Laboratóriumában.",
      availableService: {
        "@type": "MedicalProcedure",
        name: "Vizelet analízis",
        procedureType: "https://schema.org/MedicalProcedure",
      },
    },

    // Visual Assets
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/vizelet_category_card_398x200.png",
    picAttr: "Image by Freepik",
    heroImage:
      "/assets/medical/examinations/categories/vizelet/vizelet_banner_nagy.png",
    heroImageSmall:
      "/assets/medical/examinations/categories/vizelet/vizelet_banner_kicsi.png",

    typeOfUser: "civil",
  },
  {
    id: 6,
    name: "Véralvadás",
    description: "",
    // SEO and Routing Configuration
    urlSlug: "veralvadas",
    categoryType: "véralvadás",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/veralvadas",
    metaDescription:
      "Véralvadási paraméterek vizsgálata a Bálint Analitika Laboratóriumban. INR, protrombin idő, APTI és egyéb véralvadási faktorok mérése.",
    metaKeywords:
      "véralvadás vizsgálat, INR, protrombin, véralvadási faktorok, trombózis",

    // Schema.org structured data for rich snippets
    schemaData: {
      "@context": "https://schema.org",
      "@type": "MedicalTest",
      name: "Véralvadási vizsgálatok",
      description:
        "Véralvadási paraméterek vizsgálata a Bálint Analitika Laboratóriumban.",
      availableService: {
        "@type": "MedicalProcedure",
        name: "Véralvadási teszt",
        procedureType: "https://schema.org/BloodTest",
      },
    },

    // Visual Assets
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/veralvadas_category_card_398x200.png",
    picAttr: "Image by Freepik",
    heroImage:
      "/assets/medical/examinations/categories/veralvadas/veralvadas_banner_nagy.png",
    heroImageSmall:
      "/assets/medical/examinations/categories/veralvadas/veralvadas_banner_kicsi.png",

    typeOfUser: "civil",
  },
  {
    id: 7,
    name: "Diabétesz",
    description: "",
    // SEO and Routing Configuration
    urlSlug: "diabetesz",
    categoryType: "diabétesz",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/diabetesz",
    metaDescription:
      "Diabétesz vizsgálatok és cukorbetegség szűrés a Bálint Analitika Laboratóriumban. Vércukorszint, HbA1c, OGTT és egyéb diabétesz tesztek.",
    metaKeywords:
      "diabétesz vizsgálat, cukorbetegség szűrés, HbA1c, vércukorszint, OGTT",

    // Schema.org structured data for rich snippets
    schemaData: {
      "@context": "https://schema.org",
      "@type": "MedicalTest",
      name: "Diabétesz vizsgálatok",
      description:
        "Diabétesz vizsgálatok és cukorbetegség szűrés a Bálint Analitika Laboratóriumban.",
      availableService: {
        "@type": "MedicalProcedure",
        name: "Diabétesz szűrés",
        procedureType: "https://schema.org/BloodTest",
      },
    },

    // Visual Assets
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/diabetesz_category_card_398x200.png",
    picAttr: "Photo by Towfiqu barbhuiya on Unsplash",
    heroImage:
      "/assets/medical/examinations/categories/diabetesz/diabetesz_banner_nagy.png",
    heroImageSmall:
      "/assets/medical/examinations/categories/diabetesz/diabetesz_banner_kicsi.png",

    typeOfUser: "civil",
  },
  {
    id: 8,
    name: "Folyadékháztartás",
    description: "",
    // SEO and Routing Configuration
    urlSlug: "folyadekhaztartas",
    categoryType: "folyadékháztartás",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/folyadekhaztartas",
    metaDescription:
      "Folyadék- és elektrolit háztartás vizsgálata a Bálint Analitika Laboratóriumban. Nátrium, kálium, klór és egyéb elektrolitok mérése.",
    metaKeywords:
      "folyadékháztartás vizsgálat, elektrolit egyensúly, nátrium, kálium, dehidratáció",

    // Schema.org structured data for rich snippets
    schemaData: {
      "@context": "https://schema.org",
      "@type": "MedicalTest",
      name: "Folyadékháztartás vizsgálatok",
      description:
        "Folyadék- és elektrolit háztartás vizsgálata a Bálint Analitika Laboratóriumban.",
      availableService: {
        "@type": "MedicalProcedure",
        name: "Elektrolit mérés",
        procedureType: "https://schema.org/BloodTest",
      },
    },

    // Visual Assets
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/folyadekhaztartas_category_card_398x200.png",
    picAttr: "Image by Freepik",
    heroImage:
      "/assets/medical/examinations/categories/folyadekhaztartas/folyadekhaztartas_banner_nagy.png",
    heroImageSmall:
      "/assets/medical/examinations/categories/folyadekhaztartas/folyadekhaztartas_banner_kicsi.png",

    typeOfUser: "civil",
  },
  {
    id: 9,
    name: "Anaémia",
    description: "",
    // SEO and Routing Configuration
    urlSlug: "anaemia",
    categoryType: "anaémia",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/anaemia",
    metaDescription:
      "Vérszegénység kivizsgálása a Bálint Analitika Laboratóriumban. Vas, ferritin, B12-vitamin és folsav meghatározás, hemoglobin és vörösvértest vizsgálatok.",
    metaKeywords:
      "vérszegénység kivizsgálás, anaemia teszt, vas, ferritin, B12-vitamin",

    // Schema.org structured data for rich snippets
    schemaData: {
      "@context": "https://schema.org",
      "@type": "MedicalTest",
      name: "Anaémia vizsgálatok",
      description:
        "Vérszegénység kivizsgálása a Bálint Analitika Laboratóriumban.",
      availableService: {
        "@type": "MedicalProcedure",
        name: "Anaémia kivizsgálás",
        procedureType: "https://schema.org/BloodTest",
      },
    },

    // Visual Assets
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/anaemia_category_card_398x200.png",
    picAttr: "Photo by Kinga Howard on Unsplash",
    heroImage:
      "/assets/medical/examinations/categories/anaemia/anaemia_banner_nagy.png",
    heroImageSmall:
      "/assets/medical/examinations/categories/anaemia/anaemia_banner_kicsi.png",

    typeOfUser: "civil",
  },
  {
    id: 10,
    name: "Vérzsírok",
    description: "",
    // SEO and Routing Configuration
    urlSlug: "verzsirok",
    categoryType: "vérzsírok",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/verzsirok",
    metaDescription:
      "Lipid profil vizsgálatok a Bálint Analitika Laboratóriumban. Koleszterin, HDL, LDL, triglicerid mérés és átfogó anyagcsere profil.",
    metaKeywords:
      "vérzsír vizsgálat, koleszterin, triglicerid, LDL, HDL, lipid profil",

    // Schema.org structured data for rich snippets
    schemaData: {
      "@context": "https://schema.org",
      "@type": "MedicalTest",
      name: "Vérzsír vizsgálatok",
      description:
        "Lipid profil vizsgálatok a Bálint Analitika Laboratóriumban.",
      availableService: {
        "@type": "MedicalProcedure",
        name: "Lipid profil",
        procedureType: "https://schema.org/BloodTest",
      },
    },

    // Visual Assets
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/verzsirok_category_card_398x200.png",
    picAttr: "Image By vecstock on Freepik",
    heroImage:
      "/assets/medical/examinations/categories/verzsirok/verzsirok_banner_nagy.png",
    heroImageSmall:
      "/assets/medical/examinations/categories/verzsirok/verzsirok_banner_kicsi.png",

    typeOfUser: "civil",
  },
  {
    id: 11,
    name: "Máj",
    description: "",
    // SEO and Routing Configuration
    urlSlug: "maj",
    categoryType: "máj",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/maj",
    metaDescription:
      "Májfunkciós vizsgálatok a Bálint Analitika Laboratóriumban. GOT, GPT, GGT, ALP, bilirubin és egyéb májenzimek meghatározása.",
    metaKeywords:
      "májfunkció vizsgálat, májenzimek, GOT, GPT, GGT, májbetegség teszt",

    // Schema.org structured data for rich snippets
    schemaData: {
      "@context": "https://schema.org",
      "@type": "MedicalTest",
      name: "Májfunkciós vizsgálatok",
      description:
        "Májfunkciós vizsgálatok a Bálint Analitika Laboratóriumban.",
      availableService: {
        "@type": "MedicalProcedure",
        name: "Májenzim teszt",
        procedureType: "https://schema.org/BloodTest",
      },
    },

    // Visual Assets
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/maj_category_card_398x200.png",
    picAttr: "Image by stefamerpik on Freepik",
    heroImage:
      "/assets/medical/examinations/categories/maj/maj_banner_nagy.png",
    heroImageSmall:
      "/assets/medical/examinations/categories/maj/maj_banner_kicsi.png",

    typeOfUser: "civil",
  },
  {
    id: 12,
    name: "Kardiális",
    description: "",
    // SEO and Routing Configuration
    urlSlug: "kardialis",
    categoryType: "kardiális",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/kardialis",
    metaDescription:
      "Kardiális laborvizsgálatok a Bálint Analitika Laboratóriumban. Troponin, CK-MB, NT-proBNP és egyéb szívmarker meghatározások.",
    metaKeywords:
      "kardiális vizsgálat, szívmarkerek, troponin, CK-MB, szívbetegség teszt",

    // Schema.org structured data for rich snippets
    schemaData: {
      "@context": "https://schema.org",
      "@type": "MedicalTest",
      name: "Kardiális vizsgálatok",
      description:
        "Kardiális laborvizsgálatok a Bálint Analitika Laboratóriumban.",
      availableService: {
        "@type": "MedicalProcedure",
        name: "Szívmarker teszt",
        procedureType: "https://schema.org/BloodTest",
      },
    },

    // Visual Assets
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/kardialis_category_card_398x200.png",
    picAttr: "Image by krakenimages.com on Freepik",
    heroImage:
      "/assets/medical/examinations/categories/kardialis/kardialis_banner_nagy.png",
    heroImageSmall:
      "/assets/medical/examinations/categories/kardialis/kardialis_banner_kicsi.png",

    typeOfUser: "civil",
  },
  {
    id: 13,
    name: "Hasnyálmirigy",
    description: "",
    // SEO and Routing Configuration
    urlSlug: "hasnyalmirigy",
    categoryType: "hasnyálmirigy",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/hasnyalmirigy",
    metaDescription:
      "Hasnyálmirigy funkciós vizsgálatok a Bálint Analitika Laboratóriumban. Amiláz, lipáz és egyéb hasnyálmirigy enzimek meghatározása.",
    metaKeywords:
      "hasnyálmirigy vizsgálat, pankreász, amiláz, lipáz, hasnyálmirigy gyulladás",

    // Schema.org structured data for rich snippets
    schemaData: {
      "@context": "https://schema.org",
      "@type": "MedicalTest",
      name: "Hasnyálmirigy vizsgálatok",
      description:
        "Hasnyálmirigy funkciós vizsgálatok a Bálint Analitika Laboratóriumban.",
      availableService: {
        "@type": "MedicalProcedure",
        name: "Hasnyálmirigy enzim teszt",
        procedureType: "https://schema.org/BloodTest",
      },
    },

    // Visual Assets
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/hasnyalmirigy_category_card_398x200.png",
    picAttr: "Image by cookie_studio on Freepik",
    heroImage:
      "/assets/medical/examinations/categories/hasnyalmirigy/hasnyalmirigy_banner_nagy.png",
    heroImageSmall:
      "/assets/medical/examinations/categories/hasnyalmirigy/hasnyalmirigy_banner_kicsi.png",

    typeOfUser: "civil",
  },
  {
    id: 14,
    name: "Fehérje",
    description: "",
    // SEO and Routing Configuration
    urlSlug: "feherje",
    categoryType: "fehérje vizsgálatok",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/feherje",
    metaDescription:
      "Fehérje vizsgálatok a Bálint Analitika Laboratóriumban. Összfehérje, albumin, immunglobulinok és egyéb fehérjék meghatározása.",
    metaKeywords:
      "fehérje vizsgálat, összfehérje, albumin, immunglobulinok, fehérje elektroforézis",

    // Schema.org structured data for rich snippets
    schemaData: {
      "@context": "https://schema.org",
      "@type": "MedicalTest",
      name: "Fehérje vizsgálatok",
      description: "Fehérje vizsgálatok a Bálint Analitika Laboratóriumban.",
      availableService: {
        "@type": "MedicalProcedure",
        name: "Fehérje teszt",
        procedureType: "https://schema.org/BloodTest",
      },
    },

    // Visual Assets
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/feherje_category_card_398x200.png",
    picAttr: "Image by Freepik",
    heroImage:
      "/assets/medical/examinations/categories/feherje/feherje_banner_nagy.png",
    heroImageSmall:
      "/assets/medical/examinations/categories/feherje/feherje_banner_kicsi.png",

    typeOfUser: "civil",
  },
  {
    id: 15,
    name: "Allergológia",
    description: "",
    // SEO and Routing Configuration
    urlSlug: "allergologia",
    categoryType: "allergológia",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/allergologia",
    metaDescription:
      "Allergológiai vizsgálatok a Bálint Analitika Laboratóriumban. IgE, specifikus allergének kimutatása és egyéb allergia tesztek.",
    metaKeywords:
      "allergológia, allergia vizsgálat, IgE teszt, specifikus allergének, allergia panel",

    // Schema.org structured data for rich snippets
    schemaData: {
      "@context": "https://schema.org",
      "@type": "MedicalTest",
      name: "Allergológiai vizsgálatok",
      description:
        "Allergológiai vizsgálatok a Bálint Analitika Laboratóriumban.",
      availableService: {
        "@type": "MedicalProcedure",
        name: "Allergia teszt",
        procedureType: "https://schema.org/BloodTest",
      },
    },

    // Visual Assets
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/allergologia_category_card_398x200.png",
    picAttr: "Image by Freepik",
    heroImage:
      "/assets/medical/examinations/categories/allergologia/allergologia_banner_nagy.png",
    heroImageSmall:
      "/assets/medical/examinations/categories/allergologia/allergologia_banner_kicsi.png",

    typeOfUser: "civil",
  },
  {
    id: 16,
    name: "Tumormarkerek",
    description: "",
    // SEO and Routing Configuration
    urlSlug: "tumormarkerek",
    categoryType: "tumor markerek",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/tumormarkerek",
    metaDescription:
      "Tumormarker vizsgálatok a Bálint Analitika Laboratóriumban. CEA, AFP, CA 19-9, CA 125, PSA és egyéb tumormarkerek meghatározása.",
    metaKeywords:
      "tumormarker, daganatmarker, CEA, PSA, AFP, CA 125, rákszűrés",

    // Schema.org structured data for rich snippets
    schemaData: {
      "@context": "https://schema.org",
      "@type": "MedicalTest",
      name: "Tumormarker vizsgálatok",
      description:
        "Tumormarker vizsgálatok a Bálint Analitika Laboratóriumban.",
      availableService: {
        "@type": "MedicalProcedure",
        name: "Tumormarker teszt",
        procedureType: "https://schema.org/BloodTest",
      },
    },

    // Visual Assets
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/tumormarker_category_card_398x200.png",
    picAttr: "Image by Freepik",
    heroImage:
      "/assets/medical/examinations/categories/tumormarker/tumormarker_banner_nagy.png",
    heroImageSmall:
      "/assets/medical/examinations/categories/tumormarker/tumormarker_banner_kicsi.png",

    typeOfUser: "civil",
  },
  {
    id: 17,
    name: "Pajzsmirigy",
    description: "",
    // SEO and Routing Configuration
    urlSlug: "pajzsmirigy",
    categoryType: "pajzsmirigy",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/pajzsmirigy",
    metaDescription:
      "Pajzsmirigy laborvizsgálatok a Bálint Analitika Laboratóriumban. TSH, T3, T4, anti-TPO és egyéb pajzsmirigy hormon vizsgálatok.",
    metaKeywords:
      "pajzsmirigy vizsgálat, TSH, T3, T4, pajzsmirigy hormonok, anti-TPO",

    // Schema.org structured data for rich snippets
    schemaData: {
      "@context": "https://schema.org",
      "@type": "MedicalTest",
      name: "Pajzsmirigy vizsgálatok",
      description:
        "Pajzsmirigy laborvizsgálatok a Bálint Analitika Laboratóriumban.",
      availableService: {
        "@type": "MedicalProcedure",
        name: "Pajzsmirigy hormon teszt",
        procedureType: "https://schema.org/BloodTest",
      },
    },

    // Visual Assets
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/pajzsmirigy_category_card_398x200.png",
    picAttr: "Image by Freepik",
    heroImage:
      "/assets/medical/examinations/categories/pajzsmirigy/pajzsmirigy_banner_nagy.png",
    heroImageSmall:
      "/assets/medical/examinations/categories/pajzsmirigy/pajzsmirigy_banner_kicsi.png",

    typeOfUser: "civil",
  },
  {
    id: 18,
    name: "Kórokozók kimutatása",
    description: "",
    // SEO and Routing Configuration
    urlSlug: "korokozok",
    categoryType: "kórokozók kimutatása",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/korokozok",
    metaDescription:
      "Kórokozók (vírusok, baktériumok, gombák, paraziták) kimutatása a Bálint Analitika Laboratóriumban. PCR és szerológiai vizsgálatok.",
    metaKeywords:
      "kórokozó kimutatás, vírus, baktérium, gomba, parazita teszt, PCR vizsgálat",

    // Schema.org structured data for rich snippets
    schemaData: {
      "@context": "https://schema.org",
      "@type": "MedicalTest",
      name: "Kórokozó kimutatás",
      description:
        "Kórokozók (vírusok, baktériumok, gombák, paraziták) kimutatása a Bálint Analitika Laboratóriumban.",
      availableService: {
        "@type": "MedicalProcedure",
        name: "Kórokozó diagnosztika",
        procedureType: "https://schema.org/PathologyTest",
      },
    },

    // Visual Assets
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/korokozok_category_card_398x200.png",
    picAttr: "Image by Freepik",
    heroImage:
      "/assets/medical/examinations/categories/korokozok/korokozok_banner_nagy.png",
    heroImageSmall:
      "/assets/medical/examinations/categories/korokozok/korokozok_banner_kicsi.png",

    typeOfUser: "civil",
  },
  {
    id: 19,
    name: "Egyéb hormonok vizsgálata",
    description: "",
    // SEO and Routing Configuration
    urlSlug: "egyeb-hormonok",
    categoryType: "hormonok",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/egyeb-hormonok",
    metaDescription:
      "Átfogó hormonvizsgálatok a Bálint Analitika Laboratóriumban. Nemi hormonok, stresszhormonok, mellékvese és növekedési hormonok mérése.",
    metaKeywords:
      "hormon vizsgálat, ösztrogén, tesztoszteron, progeszteron, kortizol, növekedési hormon",

    // Schema.org structured data for rich snippets
    schemaData: {
      "@context": "https://schema.org",
      "@type": "MedicalTest",
      name: "Hormonvizsgálatok",
      description:
        "Átfogó hormonvizsgálatok a Bálint Analitika Laboratóriumban.",
      availableService: {
        "@type": "MedicalProcedure",
        name: "Hormonvizsgálat",
        procedureType: "https://schema.org/BloodTest",
      },
    },

    // Visual Assets
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/egyeb_hormon_category_card_398x200.png",
    picAttr: "Image by jcomp on Freepik",
    heroImage:
      "/assets/medical/examinations/categories/egyeb_hormon/egyeb_hormon_banner_nagy.png",
    heroImageSmall:
      "/assets/medical/examinations/categories/egyeb_hormon/egyeb_hormon_banner_kicsi.png",

    typeOfUser: "civil",
  },
  {
    id: 20,
    name: "Egyéb vizsgálatok",
    description: "",
    // SEO and Routing Configuration
    urlSlug: "egyeb-vizsgalatok",
    categoryType: "egyéb vizsgálatok",
    linkSrc: "/orvosdiagnosztika/vizsgalatok/egyeb-vizsgalatok",
    metaDescription:
      "Specializált laborvizsgálatok a Bálint Analitika Laboratóriumban. Ritka betegségek, speciális paraméterek és komplex panelok vizsgálata.",
    metaKeywords:
      "laborvizsgálat, speciális teszt, ritka betegség, genetikai teszt, labor diagnosztika",

    // Schema.org structured data for rich snippets
    schemaData: {
      "@context": "https://schema.org",
      "@type": "MedicalTest",
      name: "Egyéb laboratóriumi vizsgálatok",
      description:
        "Specializált laborvizsgálatok a Bálint Analitika Laboratóriumban.",
      availableService: {
        "@type": "MedicalProcedure",
        name: "Speciális laboratóriumi vizsgálat",
        procedureType: "https://schema.org/MedicalProcedure",
      },
    },

    // Visual Assets
    picLinkSrc:
      "/assets/medical/examinations/categories/categories_card/egyeb_vizsgalatok_category_card_398x200.png",
    picAttr: "Photo by Testalize.me on Unsplash",
    heroImage:
      "/assets/medical/examinations/categories/egyeb_vizsgalatok/egyeb_vizsgalatok_banner_nagy.png",
    heroImageSmall:
      "/assets/medical/examinations/categories/egyeb_vizsgalatok/egyeb_vizsgalatok_banner_kicsi.png",

    typeOfUser: "civil",
  },
];

// Create a more detailed provider object with complete address
const balintProvider = {
  "@type": "MedicalOrganization",
  name: "Bálint Analitika Laboratórium",
  address: {
    "@type": "PostalAddress",
    streetAddress: "Kondorfa utca 6.",
    addressLocality: "Budapest",
    postalCode: "1116",
    addressCountry: "HU",
  },
  telephone: "+36-1-205-3044",
  email: "orvosdiagnosztika@balintanalitika.hu",
  url: "https://balintanalitika.hu",
  // Opening hours
  openingHoursSpecification: [
    {
      "@type": "OpeningHoursSpecification",
      dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
      opens: "07:00",
      closes: "16:00",
    },
  ],

  // Geo coordinates (you'd need to get the precise coordinates)
  geo: {
    "@type": "GeoCoordinates",
    latitude: "47.453428", // Few meters off the actual location
    longitude: "19.043765", // Few meters off the actual location
  },
};

// Add this provider to all schema objects
examinationsGroupArray.forEach((category) => {
  if (category.schemaData) {
    category.schemaData.provider = balintProvider;
  }
});

// Find specific important categories
const mintavetelItem = examinationsGroupArray.find(
  (item) => item.name === "Mintavétel"
);
const otthoniMintavetelItem = examinationsGroupArray.find(
  (item) => item.name === "Otthoni mintavétel"
);
// Get all other categories
const remainingItems = examinationsGroupArray.filter(
  (item) => item.name !== "Mintavétel" && item.name !== "Otthoni mintavétel"
);

// Sort the remaining items alphabetically by the 'name' property
remainingItems.sort((a, b) => a.name.localeCompare(b.name));

// Combine "Mintavétel", Otthoni Mintavétel and the sorted items
export const sortedExaminationsGroupArray = [
  mintavetelItem,
  otthoniMintavetelItem,
  ...remainingItems,
];
