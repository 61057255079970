import React from "react";
import "./examCTA.css";
import ExamCTAList from "./ExamCTAList";

const ExamCTA = ({ isOtthoniMintavetel }) => {
  return (
    <div className="exam-company-cta-box">
      {isOtthoniMintavetel ? (
        <>
          <p className="exam-company-contact-text mg-bt-1">
            Időpontfoglaláshoz kérjük válasszon az alábbi telefonszámok közül.
            Az otthoni mintavételre történő időpontfoglalás csak munkanapokon, 8:00-15:00 között lehetséges.
          </p>
          <p className="exam-company-contact-text mg-bt-1">
            {" "}
            A laborvizsgálati előkészületekről{" "}
            <a href="/orvosdiagnosztika/vizsgalatok/kategoriak/mintavetel">
              <strong>itt</strong>
            </a>{" "}
            olvashat.
          </p>
        </>
      ) : (
        <p className="exam-company-contact-text mg-bt-1">
          Időpontfoglaláshoz kérjük válasszon az alábbi lehetőségek közül. Az
          online időpontfoglaló rendszeren keresztül a nap 24 órájában foglalhat
          vizsgálatainkra időpontot, míg a telefonos és e-mail-es
          időpontfoglalás csak munkanapokon 8:00-15:00 között lehetséges. A
          laborvizsgálati előkészületekről{" "}
          <a href="/orvosdiagnosztika/vizsgalatok/mintavetel">
            <strong>itt</strong>
          </a>{" "}
          olvashat.
        </p>
      )}
      <ExamCTAList isOtthoniMintavetel={isOtthoniMintavetel} />
    </div>
  );
};

export default ExamCTA;
