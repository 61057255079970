import React from "react";
import { Helmet } from "react-helmet-async";
import "./examCategoryMintavetel.css";
import HeroBanner from "../../../Hero/HeroBanner";
import heroImage from "../../../../../assets/medical/examinations/categories/otthoni_vervetel/otthoni_vervetel_banner_nagy.png";
import heroImageSmall from "../../../../../assets/medical/examinations/categories/otthoni_vervetel/otthoni_vervetel_banner_kicsi.png";
import ExamCTA from "../../ExamCTA/ExamCTA";

const ExamCategoryOtthoniMintavetel = () => {
  return (
    <>
      {" "}
      <Helmet>
        <title>Vérvétel és Mintavétel | Bálint Analitika</title>
        <meta
          name="description"
          content="Kényelmes otthoni mintavétel szolgáltatás Budapesten és környékén. Professzionális vérvétel az otthonában a Bálint Analitika szakértőitől."
        />
        <link
          rel="canonical"
          href="https://balintanalitika.hu/orvosdiagnosztika/vizsgalatok/otthoni-mintavetel"
        />

        {/* Add structured data for medical services */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "MedicalTest",
            name: "Otthoni mintavétel és vérvétel",
            description:
              "Kényelmes otthoni vérvétel és mintavétel szolgáltatás a Bálint Analitika szakértőitől.",
            availableService: {
              "@type": "MedicalProcedure",
              name: "Otthoni vérvétel és mintagyűjtés",
              procedureType: "https://schema.org/BloodTest",
            },
          })}
        </script>
      </Helmet>
      <HeroBanner srcBig={heroImage} srcSmall={heroImageSmall} />
      <div className="container">
        <h1 className="heading-secondary--left exam-information-h1">
          Otthoni vérvétel
        </h1>
        <p className="exam-information-text">
          <strong>
            Az otthoni vérvételeket hétfőtől péntekig, minden munkanap, 7 órától
            10 óráig biztosítjuk, csakis előzetes telefonos egyeztetés alapján.{" "}
          </strong>
        </p>{" "}
        <p className="exam-information-text">
          <strong>
            Kérjük, hogy minimum 48 órával a kívánt időpont előtt jelezzék
            vizsgálati igényüket!
          </strong>{" "}
        </p>
        <p className="exam-information-text">
          Nincs várakozás, előre egyeztetett időpontban érkezünk Önhöz a
          mintavételre. A vizsgálatok elvégzéséhez beutaló nem szükséges. A
          vizsgálatok előzetes <strong>időpont-egyeztetésére</strong> hétköznap
          8 és 15 óra között a +36-1-205-30-44 (vezetékes) vagy a
          +36-20-254-25-91-as (mobil) telefonszámon van lehetőség.{" "}
        </p>
        <p className="exam-information-text">
          Otthoni mintavétel szolgáltatásunk az alábbi helyeken érhető el:
        </p>
        <ul className="exam-information-text">
          <li>Budapest XI. kerület</li>
          <li>Budapest XXII. kerület</li>
          <li>Budapest IX. kerület</li>
        </ul>
        <p className="exam-information-text">
          A kiszállás díja <strong>4 000 Ft</strong>. A kiszállás során kért
          laborvizsgálatok fizetős vizsgálatként vehetők igénybe.
        </p>
        <p className="exam-information-text">
          A laboratóriumi vizsgálatok igénybevételekor az árjegyzékünkben
          feltüntetett mintavételi és mintaszolgáltatási díjak is fizetendők. Az
          otthoni mintavételi díjak a pontos cím ismeretében kerülnek
          kiszámolásra, melyről a szolgáltatás megrendelése előtt mindenképpen
          tájékoztatjuk Önt. Az eredményeket -{" "}
          <strong>hozzájárulása esetén </strong>- az Általános Adatvédelmi
          Rendeletnek (GDPR) megfelelően az Ön által megadott e-mail címére
          megküldjük, kérésére orvosának is továbbítjuk.
        </p>
        <div className="prices-wrapper">
          <p className="prices-file-text">
            Teljes vizsgálati lista megtekintése árakkal
          </p>
          <a
            href="/assets/medical/prices/balintanalitika_orvosdiagnosztika_arlista_20240704.pdf"
            target="_blank"
            rel="noreferrer"
            className="btn btn-lg prices-download-button"
          >
            PDF
          </a>
        </div>
        <h2 className="heading-secondary--left exam-information-h2">
          Éhgyomorra végzendő vizsgálataink
        </h2>
        <div>
          <p className="exam-information-text">
            <strong>
              Az éhgyomorra végzendő vizsgálatokat ajánlatos 9 óráig megkezdeni
            </strong>
            . Előtte 10-12 órával zsíros, fehérje dús táplálék fogyasztása nem
            ajánlott. Tartós éhezés is kerülendő. Folyadék fogyasztása
            engedélyezett cukormenetes folyadék formájában. Gyógyszereket
            ajánlatos mintavétel után bevenni. Éhgyomorra végzendő vizsgálatok:
          </p>
          <ul className="exam-information-text-list exam-package-szures-box-list-grid">
            <li className="exam-package-szures-box-list-item">Vércukor</li>
            <li className="exam-package-szures-box-list-item">Inzulin</li>
            <li className="exam-package-szures-box-list-item">
              Hemoglobin A1c
            </li>
            <li className="exam-package-szures-box-list-item">
              Mennyiségi vérkép
            </li>
            <li className="exam-package-szures-box-list-item">Vas</li>
            <li className="exam-package-szures-box-list-item">Ferritin</li>
            <li className="exam-package-szures-box-list-item">B-12 vitamin</li>
            <li className="exam-package-szures-box-list-item">Folsav</li>
            <li className="exam-package-szures-box-list-item">
              Vérzsírok:
              <br />
              Cholesterin, HDL- cholesterin, LDL- cholesterin, TG
            </li>
            <li className="exam-package-szures-box-list-item">
              Máj enzimek:
              <br />
              SGOT, SGPT, GGT, Alk. foszfatáz, bilirubin (total és direkt)
            </li>
            <li className="exam-package-szures-box-list-item">
              Fehérjék:
              <br />
              Albumin, Összfehérje, Immunglobulin, IgG-A-M, IgE, CRP
            </li>

            <li className="exam-package-szures-box-list-item">
              Kardiális paraméterek:
              <br />
              CK, CK-MB, LDH, Homocisztein
            </li>

            <li className="exam-package-szures-box-list-item">
              Folyadékháztartás:
              <br />
              CN, Kreatinin, Húgysav, Na-K, Zn Foszfor, Ca, Szelén
            </li>
          </ul>
        </div>
        <h2 className="heading-secondary--left exam-information-h2">
          Vizeletvizsgálat
        </h2>
        <p className="exam-information-text">
          A vizeletmintákat mindig tiszta edényben kell felfogni és szállítani.
          Steril edényzet csak mikrobiológiai vizsgálatokhoz szükséges.
          Amennyiben a vizsgálat időzíthető, menstruáció ideje alatt kerülni
          kell a vizelet mintavételt.
        </p>
        <h2 className="heading-secondary--left exam-information-h2">
          Vizeletgyűjtés
        </h2>
        <p className="exam-information-text exam-information-text-last">
          Kvantitatív vizsgálat céljára (pl. vizelet glukóz mennyiségi
          meghatározás, jód meghatározás) egy bizonyos időtartamon belül a
          vizeletet hiánytalanul gyűjteni kell. A legtöbb mennyiségi analízishez
          átalában - ha az orvos másképpen nem rendeli - 24 óráig kell gyűjteni
          a vizeletet. A gyűjtést alaposan kimosott, kiöblített edénybe kell
          végezni, amelyből tiszta üvegben kb. 50 ml-t kell elhozni a
          laboratóriumba. A lemért teljes gyűjtési mennyiségét közölni kell
          munkatásrunkkal. A vizeletgyűjtés napján 1,0-1,5 liter folyadékot
          igyon, de ennél többet ne. Amíg vizeletét gyűjti, szeszes italt
          fogyasztása tilos.
        </p>
        <h2 className="heading-secondary--left exam-information-h2">
          Aerob kórokozók kimutatása
        </h2>
        <p className="exam-information-text exam-information-text-last">
          Aerob tenyésztéses vizsgálat pozitivitása esetén az antibiotikum
          érzékenységi vizsgálatot utólag kell megtéríteni.
        </p>
        <ExamCTA isOtthoniMintavetel={true} />
      </div>
    </>
  );
};

export default ExamCategoryOtthoniMintavetel;
