import React from "react";
import { Helmet } from "react-helmet-async";
import HeroBanner from "../../../Hero/HeroBanner";
import "./examCategoryPage.css";
// import ExamCategoryMintavetel from "./ExamCategoryMintavetel";
import ExamCategoryOtthoniMintavetel from "./ExamCategoryOtthoniMintavetel";
import ExamCTA from "../../ExamCTA/ExamCTA";

const ExamCategoryPage = ({
  pageTitle,
  categoryType,
  exams,
  heroImage,
  heroImageSmall,
  metaDescription,
  metaKeywords,
  schemaData,
  canonicalUrl,
  urlSlug,
}) => {
  // Generate SEO content - these were missing!
  const seoTitle = `${pageTitle} vizsgálatok | Balint Analitika`;
  const seoDescription = metaDescription || 
    `${pageTitle} laboratóriumi vizsgálatok a Balint Analitika-nál. Részletes információk és időpontfoglalás.`;

  if (categoryType === "otthoni_mintavétel") {
    return <ExamCategoryOtthoniMintavetel />;
  } else {
    return (
      <>
        <Helmet>
          <title>{seoTitle}</title>
          <meta name="description" content={seoDescription} />
          {metaKeywords && <meta name="keywords" content={metaKeywords} />}
          <link
            rel="canonical"
            href={
              canonicalUrl ||
              `https://balintanalitika.hu/orvosdiagnosztika/vizsgalatok/${
                urlSlug || categoryType
              }`
            }
          />

          {/* Open Graph tags */}
          <meta property="og:title" content={seoTitle} />
          <meta property="og:description" content={seoDescription} />
          <meta
            property="og:url"
            content={
              canonicalUrl ||
              `https://balintanalitika.hu/orvosdiagnosztika/vizsgalatok/${
                urlSlug || categoryType
              }`
            }
          />
          <meta property="og:type" content="website" />

          {/* Structured data for medical services */}
          {schemaData && (
            <script type="application/ld+json">
              {JSON.stringify(schemaData)}
            </script>
          )}
        </Helmet>
        
        {/* Add a safety check for hero images */}
        {heroImage && heroImageSmall && (
          <HeroBanner
            srcBig={heroImage}
            srcSmall={heroImageSmall}
          />
        )}
        
        <div className="container exam-category-page-container">
          <h1 className="exam-category-page-title">
            {pageTitle} kategóriában a következő vizsgálataink szerepelnek:{" "}
          </h1>
          <ul className="exam-category-page-list exam-package-szures-box-list-grid">
            {exams.map((exam) => {
              if (exam.typeOfExamination === categoryType) {
                return (
                  <li
                    key={exam.id}
                    className="exam-package-szures-box-list-item"
                  >
                    {exam.name}
                  </li>
                );
              } else {
                return null;
              }
            })}
          </ul>

          <ExamCTA />
        </div>
      </>
    );
  }
};

export default ExamCategoryPage;